import {Component, EventEmitter, Output} from '@angular/core';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {SharedOfferSetRuleService} from '../../../../../service/shared-offer-set-rule.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {OfferSetSharingRuleFilterDefinitionModel} from '../../../../../model/offer-set-sharing-rule-filter-definition.model';
import {DynamicDialogRef, DynamicDialogConfig} from 'primeng/dynamicdialog';
import {OfferSetSharingRuleModel} from '../../../../../model/offer-set-sharing-rule.model';
import {OfferSetSharingRuleRequestModel} from '../../../../../model/offer-set-sharing-rule-request.model';
import {OfferSetSharingRuleFilterModel} from '../../../../../model/offer-set-sharing-rule-filter.model';
import {EventEmitterService} from '../../../../../service/event-emitter.service';
import {AccountService} from '../../../../../service/account.service';
import {DashboardAccountInterface} from '../../../../../model/dashboard-account.interface';
import {SharingRuleFilterModel} from '../../../../../model/sharing-rule-filter.model';

@Component({
  selector: 'auto-shared-offer-set-rules-entry',
  templateUrl: './shared-offer-set-rules-entry.component.html',
  styleUrls: ['./shared-offer-set-rules-entry.component.scss']
})
export class SharedOfferSetRulesEntryComponent {
  ruleForm: FormGroup;
  accountFilters: any[] = [];
  offerFilters: any[] = [];
  globalFilters = [
    {
      label: 'Make',
      value: 1,
      type: 'string'
    }
  ];
  ruleData?: OfferSetSharingRuleModel;
  filterDefinitionMap = [];
  sourceAccountOptions: any[] = [];
  ruleProcessing = false;
  filterDefinitions: OfferSetSharingRuleFilterDefinitionModel[] = [];
  @Output() refreshData: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private formBuilder: FormBuilder,
    private offerSetRuleService: SharedOfferSetRuleService,
    private messageService: MessageService,
    private dialogRef: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private eventEmitterService: EventEmitterService,
    private confirmationService: ConfirmationService,
    private accountService: AccountService
  ) {
    this.ruleForm = this.formBuilder.group({
      ruleName: [''],
      sourceAccount: [''],
      offerSetFilter: this.formBuilder.array([]),
      accountFilter: this.formBuilder.array([]),
      globalFilter: this.formBuilder.array([])
    });
  }

   ngOnInit() {
    this.ruleData = this.config.data;
    this.loadFilterData();
  }

  /**
   * Populate the form with the data from the rule
   */
  populateForm(rule: OfferSetSharingRuleModel) {
    this.ruleForm.patchValue({
      ruleName: rule.ruleName,
      sourceAccount: rule.sourceAccount['id']
    });

    (this.ruleForm.get('offerSetFilter') as FormArray).clear();
    (this.ruleForm.get('accountFilter') as FormArray).clear();
    (this.ruleForm.get('globalFilter') as FormArray).clear();

    rule.offerSetSharingRuleFilters.forEach(filter => {
      const filterGroup = this.formBuilder.group({
        propertyValue: [filter.offerSetSharingRuleFilterDefinitionId],
        selectedOperator: [filter.operator],
        value: [filter.value]
      });
      if (this.filterDefinitionMap[filter.offerSetSharingRuleFilterDefinitionId] !== 'Make') {
        if (filter.filterType === 'account') {
          (this.ruleForm.get('accountFilter') as FormArray).push(filterGroup);
        } else if (filter.filterType === 'offerSet') {
          (this.ruleForm.get('offerSetFilter') as FormArray).push(filterGroup);
        }
      } else {
        if (filter.filterType === 'offerSet') {
          (this.ruleForm.get('globalFilter') as FormArray).push(filterGroup);
        }
      }
    });
  }

  /**
   * Load the account data from the service
   */
  loadAccountData() {
    //TODO LC-11406: use NcsAccounts Endpoint instead of dashboard accounts
    this.accountService.getDashboardAccounts().subscribe({
      next: (accounts: DashboardAccountInterface[]) => {
        this.sourceAccountOptions = accounts
          .filter(account => account.active)
          .map(account => ({label: account.name, value: account.accountId}));
        this.sourceAccountOptions.sort((firstItem, secondItem) => firstItem.label.localeCompare(secondItem.label));
      },
      error: () => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to load MPOP accounts.'
        });
      }
    });
  }

  /**
   * Load the filter data from the service
   */
  loadFilterData(): void {
    this.offerSetRuleService.getOfferSetSharingRuleFilterDefinition().subscribe({
      next: (filters: OfferSetSharingRuleFilterDefinitionModel[]) => {
        this.filterDefinitions = filters;
        filters.forEach(
          (filterDefinition) => {
            this.filterDefinitionMap[filterDefinition.id] = filterDefinition.filterName;
          }
        );
        this.accountFilters = filters
          .filter((filter) => filter.filterType === 'account' && filter.filterName !== 'Make')
          .map(filter => ({label: filter.filterName, value: filter.id, type: filter.dataType}));

        this.offerFilters = filters
          .filter((filter) => filter.filterType === 'offerSet' && filter.filterName !== 'Make')
          .map(filter => ({label: filter.filterName, value: filter.id, type: filter.dataType}));

        this.loadAccountData();
        if (this.ruleData) {
          this.populateForm(this.ruleData);
        }
      },
      error: () => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to load filter definitions.'
        });
      }
    });
  }

  /**
   * Add a new filter to the form
   * TODO LC-11406: add a preview button to show the filtered accounts and offer sets. The new order will be preview -> save -> apply filter to shared offer set.
   */
  saveRule() {
    this.ruleProcessing = true;
    let offerSetSharingRuleRequest = this.buildOfferSetSharingRuleRequest();
    let sharingRuleFilterRequest = this.buildSharingRuleFilterRequest(offerSetSharingRuleRequest);
    this.offerSetRuleService.getSharedOfferSetRuleFilter(sharingRuleFilterRequest).subscribe({
        next: (appliedFilters) => {
          this.offerSetRuleService.applySharedOfferSetRuleFilters(appliedFilters).subscribe({
              next: () => {
                this.saveRuleRequest(offerSetSharingRuleRequest);
              },
              error: () => {
                this.ruleProcessing = false;
                this.messageService.add({
                  severity: 'error',
                  summary: 'Error',
                  detail: 'Failed to apply filters.'
                });
              }
            }
          );
        },
        error: () => {
          this.ruleProcessing = false;
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Failed to get filtered shared offer sets.'
          });
        }
      }
    );
  }

  /**
   * Save the rule to the database
   *
   * @param offerSetSharingRuleRequest
   * @private
   */
  saveRuleRequest(offerSetSharingRuleRequest: OfferSetSharingRuleRequestModel) {
    this.ruleProcessing = true;
    this.offerSetRuleService.updateSharedOfferSetRule(offerSetSharingRuleRequest).subscribe({
        next: () => {
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Rule saved successfully.'
          });
          this.dialogRef.close();
          this.ruleForm.markAsPristine();
          this.eventEmitterService.emitEvent('loadSharedOfferSetRules');
        },
        error: () => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Failed to save rule.'
          });
          this.ruleProcessing = false;
        }
      }
    );
  }

  /**
   * Build the request object for the API call
   */
  buildOfferSetSharingRuleRequest(): OfferSetSharingRuleRequestModel {
    let offerSetSharingRuleRequest = new OfferSetSharingRuleRequestModel();
    if (this.ruleData) {
      offerSetSharingRuleRequest.offerSetSharingRuleId = this.ruleData.id;
    }
    offerSetSharingRuleRequest.ruleName = this.ruleForm.get('ruleName').value;
    offerSetSharingRuleRequest.sourceAccountId = this.ruleForm.get('sourceAccount').value;
    offerSetSharingRuleRequest.offerSetSharingRuleFilters = this.getSharingRulesFiltersForRequest();

    return offerSetSharingRuleRequest;
  }

  /**
   * Get the filters from the form and convert them to the format required for the request
   */
  private getSharingRulesFiltersForRequest(): any[] {
    let filters = [];
    this.ruleForm.get('offerSetFilter').value.forEach(filter => {
      let offerSetSharingRuleFilter = this.buildOfferSetSharingRuleFilter(filter, 'offerSet');
      filters.push(offerSetSharingRuleFilter);
    });
    this.ruleForm.get('accountFilter').value.forEach(filter => {
      let offerSetSharingRuleFilter = this.buildOfferSetSharingRuleFilter(filter, 'account');
      filters.push(offerSetSharingRuleFilter);
    });
    this.ruleForm.get('globalFilter').value.forEach((filter) => {
      this.filterDefinitions.forEach(
        (filterDefinition) => {
          if (filterDefinition.filterName === this.filterDefinitionMap[filter.propertyValue]) {
            filter.propertyValue = filterDefinition.id;
            let offerSetSharingRuleFilter = this.buildOfferSetSharingRuleFilter(filter, filterDefinition.filterType);
            filters.push(offerSetSharingRuleFilter);
          }
        }
      );
    });

    return filters;
  }

  /**
   * Build the filter object for the request
   */
  buildOfferSetSharingRuleFilter(filter: any, filterType: string): OfferSetSharingRuleFilterModel {
    let offerSetSharingRuleFilter = new OfferSetSharingRuleFilterModel();
    offerSetSharingRuleFilter.offerSetSharingRuleFilterDefinitionId = filter.propertyValue;
    offerSetSharingRuleFilter.value = filter.value;
    offerSetSharingRuleFilter.operator = filter.selectedOperator;
    offerSetSharingRuleFilter.filterType = filterType;

    return offerSetSharingRuleFilter;
  }

  /**
   * Build the sharing rule filter request
   */
  buildSharingRuleFilterRequest(offerSetSharingRuleRequest: OfferSetSharingRuleRequestModel): SharingRuleFilterModel {
    let sharingRuleFilterRequest = new SharingRuleFilterModel();
    sharingRuleFilterRequest.sourceAccountId = offerSetSharingRuleRequest.sourceAccountId;
    offerSetSharingRuleRequest.offerSetSharingRuleFilters.forEach((filter) => {
      sharingRuleFilterRequest.offerSetSharingRuleFilters.push(
        {
          filterName: this.filterDefinitionMap[filter.offerSetSharingRuleFilterDefinitionId],
          filterType: filter.filterType,
          operator: filter.operator,
          value: filter.value
        }
      );
    });

    return sharingRuleFilterRequest;
  }

  /**
   * Close the modal
   */
  closeModal() {
    if (!this.ruleForm.dirty) {
      this.dialogRef.close();
    } else {
      this.confirmationService.confirm({
        message: 'Are you sure you want to close without saving changes?',
        header: 'Unsaved Changes',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.dialogRef.close();
        },
        reject: () => {}
      });
    }
  }

  /**
   * Check if the form is invalid
   */
  isInvalidForm() {
    return this.ruleForm.get('ruleName').value === '' || this.ruleForm.get('sourceAccount').value === '';
  }
}
